import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    topSide: {
      buttonText: "Vai a tutti gli articoli",
    },
    bottomSide: {
      prevButton: "prev",
      succButton: "next",
    },
    missingContentMessage: {
      message: "Non disponibile in italiano",
      button: "Vai alla pagina in inglese",
    },
  },
  en: {
    topSide: {
      buttonText: "Go to all the articles",
    },
    bottomSide: {
      prevButton: "prev",
      succButton: "next",
    },
    missingContentMessage: {
      message: "Not available in English",
      button: "Go to Italian version",
    },
  },
};

export const l_blogarticle = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};

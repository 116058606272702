import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Zoom from "react-medium-image-zoom";

const AutoSizeZoomImage = (props: { url: string }) => {
  const [toZoom, setToZoom] = useState(false);
  const minSizeToZoom = 0; //700; //! always zoom in

  const isVideo = props.url.endsWith(".mov") || props.url.endsWith(".mp4");
  const videoRef = useRef<any>();

  useEffect(() => {
    if (isVideo) {
      // todo handle video
      setTimeout(() => {
        try {
          videoRef.current?.play();
        } catch (e) {}
      }, 1000);
      return;
    }

    let img = new Image();
    const imgLoadFunction = () => {
      // console.log(8889, img.width, img.height);
      if (img.width > minSizeToZoom || img.height > minSizeToZoom) {
        setToZoom(true);
      }
    };
    // img.onload = imgLoadFunction;
    img.addEventListener("load", imgLoadFunction);
    img.src = props.url;

    return () => {
      img.removeEventListener("load", imgLoadFunction);
    };
  }, []);

  const child = (
    <Grid
      container
      justifyContent="center"
      style={{ alignItems: "flex-start", textAlign: "center" }}
    >
      {isVideo ? (
        <video
          ref={videoRef}
          src={props.url}
          className="article-image-fitter"
          // controls
          loop
          muted
          // autoPlay={true}
          // playsInline={true}
          controls={false}
        />
      ) : (
        <img src={props.url} className="article-image-fitter" />
      )}
    </Grid>
  );

  if (!toZoom) {
    return child;
  }

  return <Zoom children={child} />;
};

export default AutoSizeZoomImage;

import { Button, ButtonProps, Tooltip } from "@mui/material";
import { ReactNode } from "react";

const StyledOrangeButtonWithIcon: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <Button
      variant="outlined"
      disableRipple
      sx={{
        color: "rgb(235, 104, 61)",
        borderColor: "rgb(235, 104, 61)",
        backgroundColor: "transparent",
        borderRadius: "56px",
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 700,
        padding: { md: "24px 60px", sm: "16px 32px", xs: "16px" },
        gap: { md: "24px", xs: "8px" },
        borderWidth: "2px",
        transition: (theme) => theme.transitions.create("background-color"),
        ":hover": {
          borderColor: "rgb(235, 104, 61)",
          borderWidth: "2px",
          backgroundColor: "rgba(235, 104, 61, 0.12)",
        },
        ":active": {
          backgroundColor: "rgba(235, 104, 61, 0.24)",
        },
      }}
      {...props}
    ></Button>
  );
};

export default StyledOrangeButtonWithIcon;

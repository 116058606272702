import { Box, Button, Tooltip } from "@mui/material";
import { useRef } from "react";
import { animated, useSpring } from "react-spring";
import { LocalizedLink } from "../../../components/LocalizedLink";
import { l_blogarticle } from "../locales";

const AnimatedBox = animated(Box);

const AllArticlesButton = () => {
  const [spring, api] = useSpring(() => {
    return {
      value: 0,
      config: {
        duration: 800,
      },
    };
  });

  const animationHasFinished = useRef(false);

  return (
    <Box sx={{ flex: 1 }}>
      <LocalizedLink
        to="/blog"
        style={{
          textDecoration: "none",
        }}
      >
        <Tooltip title={l_blogarticle("topSide.buttonText")} disableInteractive>
          <Button
            sx={{
              ":hover": {
                backgroundColor: "transparent",
              },
              backgroundColor: "transparent",
              // backgroundColor: "red",
              padding: 0,
              margin: "auto",
              width: "fit-content",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              rowGap: { md: "4px", xs: "2px" },
              columnGap: { md: "4px", xs: "2px" },
            }}
            onMouseEnter={() => {
              animationHasFinished.current = false;
              api.set({ value: 0 });
              api.start({
                value: 1,
                onResolve: () => (animationHasFinished.current = true),
              });
            }}
            onMouseLeave={() => {
              if (animationHasFinished.current) {
                api.set({ value: 0 });
              }
              api.start({
                value: 0,
              });
            }}
          >
            {[1, 2, 4, 3].map((sq, ind) => (
              <AnimatedBox
                key={sq}
                sx={{
                  width: { md: "30px", xs: "26px" },
                  height: { md: "30px", xs: "26px" },
                  border: "2px solid rgb(232, 83, 34)",
                  borderRadius: "8px",
                  transition: (theme) =>
                    theme.transitions.create("background-color"),
                }}
                style={{
                  backgroundColor: spring.value
                    .to([0, 0.25, 0.5, 0.75, 1], [0, 1, 2, 3, 4])
                    .to((value) =>
                      value < sq
                        ? "rgba(232, 83, 34, 0)"
                        : "rgba(232, 83, 34, 1)"
                    ),
                }}
              ></AnimatedBox>
            ))}
          </Button>
        </Tooltip>
      </LocalizedLink>
    </Box>
  );
};

export default AllArticlesButton;

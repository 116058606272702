function ArrowArticleIconOrangeSucc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__arrow_article_orange_succ"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={7}
        y={4}
        width={10}
        height={16}
      >
        <path
          d="M7.746 19.759a.811.811 0 01-.081-1.072l.08-.092L14.474 12 7.746 5.405a.811.811 0 01-.081-1.072l.08-.092a.852.852 0 011.094-.08l.094.08 7.321 7.177c.298.292.325.75.081 1.072l-.08.092-7.322 7.177a.851.851 0 01-1.187 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__arrow_article_orange_succ)">
        <path fill="#E85322" d="M0-.061h24v24H0z" />
      </g>
    </svg>
  );
}

export default ArrowArticleIconOrangeSucc;

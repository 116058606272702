import { Grid } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { ContentfulArticleBodyElement } from "../Article";

function extractHostname(url: string) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
}

const ExternalLinkPreview = (props: { url: string; labels: string[] }) => {
  const { url, labels } = props;
  const [title, setTitle] = useState((labels && labels[0]) || url);
  const [domain, setDomain] = useState(extractHostname(url));
  const [description, setDescription] = useState(
    labels ? labels.slice(1).join("\n") : ""
  );
  const [image, setImage] = useState(null);

  const urlCrawler = "https://crawler.mabiloft.com?url=";

  useEffect(() => {
    fetch(urlCrawler + url)
      .then((res) => res.json())
      .then((res) => {
        if (res.title) setTitle(res.title);
        if (res.description) setDescription(res.description);
        if (res.domain) setDomain(res.domain);
        if (res.image) setImage(res.image);
      });
  }, []);

  const imgWidth = 130;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Grid container item xs justifyContent="center">
        <div
          style={{
            padding: 16,
            maxWidth: 500,
            width: "100%",
            height: 140,
            marginTop: 25,
            marginBottom: 50,
            paddingRight: imgWidth,
            position: "relative",
            border: "1px solid #ccc",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
          }}
        >
          {labels ? (
            <React.Fragment>
              <div
                children={title}
                style={{
                  textDecoration: "none",
                  fontSize: "1em",
                  lineHeight: "1.5",
                  maxHeight:
                    description && description.length ? "3em" : "4.5em",
                  overflow: "hidden",
                  marginRight: 15,
                }}
              />
              <div
                children={description}
                style={{
                  marginTop: 10,
                  textDecoration: "none",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  marginRight: 15,
                  fontSize: 16,
                  lineHeight: 1.3,
                  height: 16 * 2 * 1.3,
                }}
              />
            </React.Fragment>
          ) : (
            url
          )}

          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: imgWidth,
              borderLeft: "1px solid #ccc",
              backgroundColor: image ? undefined : "#f0f0f0",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: image ? `url(${image})` : undefined,
            }}
          />

          <div
            style={{
              fontSize: "0.6em",
              opacity: 0.7,
              position: "absolute",
              bottom: 8,
              left: 16,
            }}
            children={domain}
          />
        </div>
      </Grid>
    </a>
  );
};

export function checkFullLinkElement(element: ContentfulArticleBodyElement) {
  if (!element) return null;
  const children = (element.props || {}).children || [];
  if (!children.length) return null;

  const anchors: string[] = [];
  const labels: string[] = [];
  for (var c = 0; c < children.length; c++) {
    if (!children[c]) continue;
    if (children[c]?.type && children[c]?.type === "a") {
      const a = children[c];
      if (a.props && a.props.href) {
        anchors.push(a.props.href);

        if (a.props.children && Array.isArray(a.props.children)) {
          a.props.children.forEach((subitem) => {
            if (subitem && subitem.props && subitem.props.children?.length) {
              if (typeof subitem.props.children[0] === "string") {
                labels.push(...((subitem.props.children as any) as string[])); //todo <-- assicurarsi che vengano fuori le labels nell'articolo
              }
            }
          });
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  if (!anchors.length || !anchors.every((a) => a === anchors[0])) {
    return null;
  }

  const url = anchors[0];
  return <ExternalLinkPreview url={url} labels={labels} />;
}

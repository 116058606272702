/** @jsxImportSource @emotion/react */
import { Box, Typography, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { l_blogarticle } from "../../../templates/Article/locales";
import { LocalizedSwitch } from "../../LocalizedLink";
import OutlinedButton from "../OutlinedButton";

type Props = {};

const MissingContent: React.FC<Props> = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: { md: "200px", xs: "80px" },
        marginBottom: { md: "230px", xs: "120px" },
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ marginBottom: { md: "100px", xs: "48px" }, display: "flex" }}>
        <Box
          sx={{
            // width: { lg: "195px", md: "140px", sm: "70px", xs: "48px" },
            width: { md: "105px", xs: "50px" },
            marginRight: "10px",
          }}
        >
          <StaticImage
            src={"./images/orange-circle.svg"}
            alt={""}
            width={110}
          />
        </Box>
        <Box
          sx={{
            width: { md: "220px", xs: "80px" },
            marginTop: { md: "85px", xs: "55px" },
            transform: "rotate(139deg)",
          }}
        >
          <StaticImage src={"./images/purple-lemon.svg"} alt={""} width={220} />
        </Box>
        <Box
          sx={{
            width: { md: "200px", xs: "70px" },
            marginTop: 0,
            transform: "rotate(-150deg)",
            marginBottom: { md: "85px", xs: "55px" },
          }}
        >
          <StaticImage src={"./images/blue-lemon.svg"} alt={""} width={220} />
        </Box>
      </Box>

      <Typography
        sx={{
          marginBottom: { md: "56px", xs: "16px" },
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {l_blogarticle("missingContentMessage.message")}
      </Typography>
      <LocalizedSwitch style={{ textDecoration: "none" }}>
        <OutlinedButton sx={{ padding: { md: "16px 36px", xs: "8px 24px" } }}>
          {l_blogarticle("missingContentMessage.button")}
        </OutlinedButton>
      </LocalizedSwitch>
    </Box>

    // <BodyPadding
    //   noAutoMargin
    //   containerCss={{ flexGrow: 1, width: "100%", margin: "0 auto" }}
    // >
    //   <div
    //     css={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "center",
    //       width: "100%",
    //       gap: 26,
    //       padding: 20,
    //       boxSizing: "border-box",
    //     }}
    //   >
    //     <div css={{ width: "100%" }}>
    //       <Image
    //         filename={"photos/missing_content_illustration.png"}
    //         alt=""
    //         imageProps={{
    //           imgStyle: { objectFit: "contain" },
    //           css: {
    //             height: "100%",
    //             maxHeight: 400,
    //             maxWidth: "100%",
    //           },
    //         }}
    //       />
    //     </div>
    //     <h3
    //       css={{
    //         color: "#1B203C",
    //         fontSize: 30,
    //         fontWeight: 800,
    //         ...ress("sm", { fontSize: 20 }),
    //       }}
    //       children={L("notAvailableInThisLanguage")}
    //     />
    //     <LocalizedSwitch
    //       className="btn-cta"
    //       css={{
    //         fontSize: 18,
    //         textTransform: "capitalize",
    //         color: "#E85322",
    //         textDecoration: "none",
    //         ...ress("sm", { fontSize: 16 }),
    //       }}
    //       children={
    //         <ButtonCTA
    //           // icon="/icons/icon_add.svg"
    //           children={L("goToOtherLanguage")}
    //           css={{
    //             padding: 14,
    //             boxSizing: "border-box",
    //             background: "rgba(232, 83, 34, 0.06)",
    //             color: theme.palette.secondary.main,
    //             pointerEvents: "visible",
    //             [`& [class^="MuiTypography-root"]`]: {
    //               color: theme.palette.secondary.main,
    //             },
    //           }}
    //         />
    //       }
    //     />
    //   </div>
    // </BodyPadding>
  );
};

export default MissingContent;

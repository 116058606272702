function ArrowIconOrangePrev(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__arrow_article_orange_prev"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={7}
        y={4}
        width={10}
        height={16}
      >
        <path
          d="M16.254 4.241c.298.292.325.75.081 1.072l-.08.092L9.526 12l6.727 6.595c.298.292.325.75.081 1.072l-.08.092a.852.852 0 01-1.094.08l-.094-.08-7.321-7.177a.811.811 0 01-.081-1.072l.08-.092 7.322-7.177a.852.852 0 011.187 0z"
          fill="rgb(235, 104, 61)"
        />
      </mask>
      <g mask="url(#prefix__arrow_article_orange_prev)">
        <path fill="rgb(235, 104, 61)" d="M0-.061h24v24H0z" />
      </g>
    </svg>
  );
}

export default ArrowIconOrangePrev;

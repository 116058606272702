import { Box } from "@mui/material";
import { ContentfulArticleAuthor } from "../Article";
import Img from "gatsby-image";

const AuthorBadge = (props: { author: ContentfulArticleAuthor }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          width: { md: "88px", xs: "44px" },
          height: { md: "88px", xs: "44px" },
          borderRadius: "50%",
          backgroundColor: "#eee",
          overflow: "hidden",
        }}
        className="cover-image"
      >
        <Img fluid={props.author.profileImage.fluid} />
      </Box>

      <div className="article-author">
        <p
          className="article-author-name"
          children={props.author.name + " " + props.author.surname}
        />

        <p className="article-author-role" children={props.author.role} />
      </div>
    </Box>
  );
};

export default AuthorBadge;

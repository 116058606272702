import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { ContentfulArticle, ContentfulArticleBodyElement } from "../Article";
import { language } from "../../../traductions";
import { checkFullLinkElement } from "./ExternalLinkPreview";
import { Grid } from "@mui/material";
import AutoSizeZoomImage from "./AutoSizeZoomImage";

const ArticleBody = ({ article }: { article: ContentfulArticle }) => {
  const bodyElements = documentToReactComponents(
    JSON.parse(article.body.body),
    {
      renderNode: {
        [INLINES.HYPERLINK]: (node) => {
          return (
            <a
              key={node.data.uri}
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {(node &&
                node.content &&
                node.content.map((x: any) => x.value)) ||
                node.data.uri}
            </a>
          );
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          if (!node.data.target.fields) {
            // console.log("SKIPPING IMAGE", node);
            return null;
          }

          const fields = node.data.target.fields;

          // const file = node.data.target.fields.file;
          // file[Object.keys(file)[0]]

          const file =
            fields.file[
              Object.keys(fields.file).filter((fileLang) =>
                fileLang.startsWith(`${language.code}-`)
              )[0]
            ] || fields.file[Object.keys(fields.file)[0]];

          const description = fields.description
            ? fields.description[
                Object.keys(fields.description).filter((descLang) =>
                  descLang.startsWith(`${language.code}-`)
                )[0]
              ] || fields.description[Object.keys(fields.description)[0]]
            : null;

          return (
            <img
              key={JSON.stringify(file)}
              data-file={file}
              data-description={description}
            />
          );
          // return <img data-file={node.data.target.fields.file[language]} data-description={} />;
        },
      },
    }
  ) as ContentfulArticleBodyElement[];

  const parsedBodyElements = [];

  for (var c = 0; c < bodyElements.length; c++) {
    const element = bodyElements[c];
    if (element == null) continue;

    const fullLink = checkFullLinkElement(element);
    if (fullLink) {
      //? full link external
      parsedBodyElements.push(<span key={c} children={fullLink} />);
    } else if (element?.type === "img") {
      //? image
      const imgs = [];
      while (
        bodyElements[c] &&
        bodyElements[c]?.type === "img" &&
        c < bodyElements.length
      ) {
        imgs.push(bodyElements[c]);
        c++;
      }

      const imgDescriptionSum = imgs
        .map((i) => i.props?.["data-description"] || "")
        .filter((desc) => desc && desc.trim() != "")
        .join(" | ");

      const imgGridElement = (
        <span key={"img-grid-" + c} className="blog-images-container-wrapper">
          <Grid
            container
            spacing={1}
            className="blog-images-container"
            data-count={imgs.length}
            alignItems="center"
          >
            {imgs.map((imageElement, index) => {
              const file = imageElement.props?.["data-file"];
              return (
                <Grid key={index} item xs className="grid-image-wrapper">
                  <AutoSizeZoomImage url={file.url} />
                </Grid>
              );
            })}
          </Grid>

          {imgDescriptionSum ? (
            <p
              className="blog-images-description"
              children={imgDescriptionSum}
            />
          ) : null}
        </span>
      );

      // 1 or more image elements
      parsedBodyElements.push(imgGridElement);

      // decrement counter to not skip next element
      c--;
    } else {
      // normal element
      parsedBodyElements.push(element);
    }
  }
  return <>{parsedBodyElements}</>;
};

export default ArticleBody;
